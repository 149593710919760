import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/ai-chat5.png';
import { Form, Input, Button, Typography, Row, Col } from 'antd';

const { Title, Paragraph } = Typography;

function SignUpPage() {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (values) => {
    const { username, fullName, email, password, allowedDomains } = values;

    if (!username) {
      setMessage('Username field is required.');
      return;
    }
    if (!fullName) {
      setMessage('Fullname field is required.');
      return;
    }
    if (!email) {
      setMessage('Email is required.');
      return;
    }
    if (!password) {
      setMessage('Password is required.');
      return;
    }

    try {
      // const recaptchaValue = typeof grecaptcha !== 'undefined' ? grecaptcha.getResponse() : null;
      // if (recaptchaValue != null && !recaptchaValue) {
      //   setMessage('Please complete the CAPTCHA');
      //   return;
      // }    

      // if (typeof grecaptcha !== 'undefined') {
      //   grecaptcha.reset(); // Reset the reCAPTCHA widget
      // }
  
      const response = await fetch(`${apiBaseUrl}/admin/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          full_name: fullName,
          email,
          password,
          clientid: '', // This will be generated by the backend
          allowed_domains: allowedDomains,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(`User created successfully. Please proceed to Login.`);
      } else {
        setMessage(`Error: ${data.detail[0].msg}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message} Refresh the page and try again.`);
    }
  };

  return (
    <div style={containerStyle}>
      <div style={formStyle}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <Title level={3}>OutScore</Title>
        <Title level={4}>Sign Up</Title>
        <Form onFinish={handleSubmit} layout="vertical" style={formContainerStyle}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input
                  style={inputStyle}
                  placeholder='Username'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="fullName" rules={[{ required: true, message: 'Please input your full name!' }]}>
                <Input
                  style={inputStyle}
                  placeholder='Full Name'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                <Input
                  type="email"
                  style={inputStyle}
                  placeholder= 'Email'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input
                  type="password"
                  style={inputStyle}
                  placeholder= 'Password'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="allowedDomains">
                <Input
                  style={inputStyle}
                  placeholder= 'Allowed Domains (leave empty if unsure)'
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="g-recaptcha" data-sitekey="6LfVNhoqAAAAAHIla8OiEErL5-BHA0HrIBDqjPdq"></div>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={buttonStyle}>
              Sign Up
            </Button>
          </Form.Item>
        </Form>
        {message && <Paragraph>{message}</Paragraph>}
        <Button onClick={() => navigate('/login')} style={buttonStyle}>
          Go to Login
        </Button>
      </div>
    </div>
  );
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //height: '100vh',
  padding: '20px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
};

const formContainerStyle = {
  width: '100%',
};

const logoStyle = {
  width: '150px',
  marginBottom: '20px',
};

const inputStyle = {
  width: '100%',
};

const buttonStyle = {
  width: '100%',
};
  

export default SignUpPage;
