import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, message, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const SettingsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('Sales');
  const [cssFileList, setCssFileList] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let username = localStorage.getItem('username');
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Load settings from the backend
    const loadSettings = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/admin/chatbot_settings`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            username // Send username as a query parameter
          }
        });
        const data = response.data;

        // Transform the response data to match the form's initial values
        const initialValues = {
          title_text: data.title,
          icon_url: data.icon_url,
          callout_text: data.callout_text,
          callout_delay: data.callout_delay,
          mode: data.mode,
        };

        form.setFieldsValue(initialValues);
      } catch (error) {
        message.error('Failed to load settings');
      }
    };

    loadSettings();
  }, [form]);

  const handleSave = async (values) => {
    setLoading(true);
    
    const formData = new FormData();
    formData.append('username', username);
    formData.append('settings', JSON.stringify(values));
    // Only a single file uploaded now
    cssFileList.forEach((file, index) => {
      formData.append("cssfile", file.originFileObj);
    });
    

    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
  
    axios.post(`${apiBaseUrl}/admin/chatbot_settings`, formData, { headers })
    .then (response => {
       message.success('Settings saved successfully');
    })
    .catch (error => {
      message.error('Failed to save settings');
    })
    .finally(() => {
      setCssFileList([]);
      setLoading(false);
    });
  };

  const handleModeChange = (event) => { setSelectedMode(event); };

  const props = {
    beforeUpload: (file) => {
      const isCSS = file.type === 'text/css';
      if (!isCSS) {
        message.error(`${file.name} is not a text/css file`);
      }
      const allow = cssFileList.length === 0;
      if (!allow) {
        message.error("Only a single css can be uploaded");
      }
      return (isCSS && allow) || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      setCssFileList(info.fileList);
    },
  };

  const handleDownload = () => {
    axios.get(`${apiBaseUrl}/admin/style`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        username 
      }
    })
    .then (response => {
       const blob = new Blob([response.data], { type: "text/css" });
       const url = window.URL.createObjectURL(blob);
       const link = document.createElement("a");
       link.href = url;
       link.download = "chat.css";
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
    })
    .catch (error => {
      message.error("Failed to get css style");
    })
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSave}
      initialValues={{
        title_text: 'AI Chatbot',
        icon_url: 'https://api.outscore.ai/admin/static/images/ai-chat5.png',
        callout_text: 'Click to speak!',
        callout_delay: 3000,
        mode: 'Sales',
      }}
    >
      <Form.Item label="Title Text" name="title_text">
        <Input />
      </Form.Item>
      <Form.Item label="Icon URL" name="icon_url">
        <Input />
      </Form.Item>
      <Form.Item label="Callout Text" name="callout_text">
        <Input />
      </Form.Item>
      <Form.Item label="Callout Delay (ms)" name="callout_delay">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Mode" name="mode" mode={selectedMode}>
        <Select placeholder="Select an option"
	        onChange={handleModeChange}
	        defaultValue="Sales" >
          <Option value="Sales">Sales</Option>
          <Option value="Info">Info</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="default" icon={<DownloadOutlined />} onClick={handleDownload}>
          Current CSS File
        </Button>
      </Form.Item>
      <Form.Item>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Upload CSS</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SettingsForm;
